import React from "react";
import Layout from "../components/layout";
import MainMenu from "../components/MainMenu/MainMenu";
import Footer from "../components/Footer/Footer";
import { useStaticQuery, graphql } from "gatsby";
import CopyRights from "../components/Footer/CopyRights/CopyRights";
import SEO from "../components/seo";
import "../assets/css/main.css";
import Header from "../components/Header/Header";
import HeadingCTA from "../components/HeadingCTA/HeadingCTA";
import CaseStudyHeaderImage from "../images/casestudybg.png";
import Summary from "../components/CaseStudy/Summary/Summary";
import LetsGetStarted from "../components/LetsGetStarted/LetsGetStarted";
import { StaticImage } from "gatsby-plugin-image";

const CaseStudy = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "casestudy/bannerdakarrally.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <MainMenu />
      <SEO
        seo={{
          shareImage: data.bannerImage.childImageSharp.fixed.src,
          metaTitle: "Transportation Solutions - Case Studies",
          metaDescription:
            "We are MOTUS | ONE, we solve complex problems through transport ideas."
        }}
      />
      <Header
        image={
          <StaticImage src="../assets/images/casestudy/bannerdakarrally.jpg" />
        }
        halfHeight
        breadcrumbs={[{ label: "Case Studies" }]}
      >
        <HeadingCTA dark title={"Case studies"} h1 />
      </Header>

      <Summary
        category={"Case Study"}
        title={"Supplying and running technical buses for Dakar Rally"}
        image={
          <StaticImage src="../assets/images/casestudy/CASE-STUDY-DAKAR-OUTCOMES.jpg" />
        }
        link={"/case-studies/dakar-rally/"}
        description={
          <>
            The 2020 Dakar Rally was the first to be held in the UAE. Our team
            had just two months to deliver the event’s critical fleet of
            technical support vehicles.
          </>
        }
      />

      <Summary
        category={"Case Study"}
        title={"Ad-Diriyah Seasons, Riyadh"}
        image={<StaticImage src="../assets/images/casestudy/formulae.png" />}
        link={"/case-studies/ad-diriyah-seasons-riyadh/"}
        description={
          <>
            Saudi Arabia’s Diriyah Season has hosted many exciting one - off
            events, including concerts, a Formula E Prix and the historic
            professional heavyweight Clash on the Dunes fight...
          </>
        }
      />

      <Summary
        category={"Case Study"}
        title={"Etihad Airways, Abu Dhabi Formula One® Grand Prix"}
        image={
          <StaticImage src="../assets/images/casestudy/CASE-STUDY-Etihad Airways, Abu Dhabi Formula One® Grand Prix.jpg" />
        }
        link={"/case-studies/abu-dhabi-formula-one/"}
        description={
          <>
            In September 2009, MOTUS | ONE members were contracted by the Abu
            Dhabi Department of Transportation to assist with the inaugural
            Formula 1™ Grand Prix at Yas Marina Circuit, Abu Dhabi, UAE...
          </>
        }
      />

      <Summary
        category={"Case Study"}
        title={"NBA India Games"}
        image={
          <StaticImage src="../assets/images/casestudy/nba/CASE-STUDY-NBA INDIA GAMES.jpg" />
        }
        link={"/case-studies/nba-india-games/"}
        description={
          <>
            NBA India on December 20, 2018, had announced that the first - ever
            NBA game in the country would be organized on October 4 and 5 in
            Mumbai...
          </>
        }
      />

      <Summary
        category={"Case Study"}
        title={"Strategic Fleet Optimization & Utilization"}
        image={
          <StaticImage src="../assets/images/casestudy/CASE-STUDY-Strategic Fleet Optimization.jpg" />
        }
        link={"/case-studies/strategic-fleet-optimization-utilization/"}
        description={
          <>
            MOTUS | ONE ties your transport and logistics plans into one
            centralized platform. Utilizing a combination of mobile handhelds,
            OBD II devices, and a web-based management dashboard, this system
            takes the hassle out of planning and implementation processes by
            automating schedules, assigning routes to drivers, tracking vehicles
            in operation..
          </>
        }
      />

      <Summary
        category={"Case Study"}
        title={"Transportation Solutions – Rethought"}
        image={
          <StaticImage src="../assets/images/casestudy/CASE-STUDY-Transportation Solutions – Rethought.jpg" />
        }
        link={"/case-studies/transportation-solutions-rethought/"}
        description={
          <>
            Partner with us to find bold ways to plan and implement the most
            complex transport systems. We’re by your side – from initial
            conception right through to delivery and implementation. Use our
            strengths in diligent research and innovative thinking to open new
            avenues of commercial advantage.
          </>
        }
      />

      <LetsGetStarted />
      <Footer />
      <CopyRights />
    </Layout>
  );
};

export default CaseStudy;
